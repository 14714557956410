import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StorageParams } from '../../constants/StorageParams';
import { GatewayApiService } from '../api/gateway.api.service';
import { UsersApiService } from '../api/users.api.service';
import { SharedService } from '../shared.service';


@Injectable()
export class UsersBusinessService{

    private userProfileLoadInProgress = false;


    constructor(  private usersApiService : UsersApiService,
                  private gatewayApiService : GatewayApiService,
                  private router : Router,
                  private sharedService : SharedService,
                  private translate:  TranslateService  ){

/*
                    TODO
        

        window.addEventListener('beforeunload', (event) => {
            this.logout();
        });
*/
        this.userProfileLoadInProgress = false;

    }

    
   /**
   * Load user profile in silence
   */
  public loadUserProfile() {
     this.loadUserProfileAndRedirect( null );
  }


  /**
   * Load user profile
   */
  public loadUserProfileAndRedirect( redirectionUrl : string ) {

    // Load profile already in progress ?
    if( this.userProfileLoadInProgress ){
        return;
    }

    this.userProfileLoadInProgress = true;
    this.usersApiService.getMyUserProfile().subscribe(data => {
        this.userProfileLoadInProgress = false;
        this.setDefaultTranslationsLanguage( data.languageId );
        
        // Profile is now fully loaded and keys decrypted, emit to listeners
        this.sharedService.emitUserProfileLoaded(data);

        if ( redirectionUrl ){   
            this.router.navigate([redirectionUrl])
        }
    }, error => {
        this.userProfileLoadInProgress = false;
        console.log(error);
        this.logout();
    }) 
  }

    /**
     * Call api to validate session
     */
    public checkIsLogged(){
        this.gatewayApiService.logged().subscribe(isLogged => {
            if (! isLogged ){
                this.goToLoginPage();
            }
        }, error => {
            this.goToLoginPage();
        })     
    }



    /**
     * Logout
     */
    public logout(){
        this.cleanStorage();
        this.gatewayApiService.logout().subscribe(success => {
            this.goToLoginPage();
        }, error => {
            console.log(error)
            this.goToLoginPage()
        })     
    }





    /**
     * Got to login page
     */
    public goToLoginPage( ){   
        this.router.navigate(['/authentication/login'])
    }



        /**
     * Got to Home page
     */
    public goToHomePage( ){   
        this.router.navigate(['/dashboard'])
    }
  

    /**
     * Set default language
     * @param defaultLanguage 
     */
    public setDefaultTranslationsLanguage(defaultLanguage: string) {
        //console.log( "setDefaultTranslationsLanguage = " + defaultLanguage );
        this.translate.setDefaultLang(defaultLanguage);
        this.translate.use(defaultLanguage);
    }





    /**
     * Clean storage infos
     */
    public cleanStorage() {
        sessionStorage.clear();
        //localStorage.clear(); // Dont clean for user settings 
    }

}