import { Component, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { UserProfile } from '../../../models/UserProfile';
import { UsersApiService } from '../../../services/api/users.api.service';
import { UsersBusinessService } from '../../../services/business/users.business.service';
import { SharedService } from '../../../services/shared.service';
import { StorageParams } from '../../../constants/StorageParams';
import { Indicators } from '../../../models/Indicators';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent implements OnInit {


  public config: PerfectScrollbarConfigInterface = {};

  public userProfile : UserProfile;
  public indicators : Indicators;
  public notificationsBlinking = false;
  

  constructor( private usersApiService : UsersApiService,
               private sharedService: SharedService,
               private usersBusinessService: UsersBusinessService ){


  }
  
  /**
   * Init
   */
  ngOnInit(): void {

     this.sharedService.onProfileLoaded.subscribe((data:UserProfile) => {
        this.userProfile = data;
     });

     this.sharedService.onIndicatorsLoaded.subscribe((data:Indicators) => {
         this.indicators = data;
     });

     this.sharedService.onContactConfirm.subscribe((data) => {
        if( data != null ){
            this.usersBusinessService.loadUserProfile();
        }
     });

  }


  /**
   * Logout
   */
  logout(){
    this.usersBusinessService.logout()
  }


}
