import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'transfert-modal-content',
    templateUrl: './transfert-modal-content.html',
})
export class TransfertModalContent implements OnInit {

    constructor(  ) {

    }


    ngOnInit(): void {

    }


}