import { Injectable } from '@angular/core';
import { CryptoService } from '../crypto.service';
import { DhPublicKey } from '../../models/DhPublicKey';
import { StorageParams } from '../../constants/StorageParams';
import { LoginRequest } from '../../models/LoginRequest';
import { MatDialog } from '@angular/material/dialog';
import { DhSecretKey } from '../../models/DhSecretKey';
import { DomSanitizer} from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';
import { RegisterFormData } from '../../models/RegisterFormData';

@Injectable()
export class EncryptionService {

    constructor( private cryptoService:CryptoService,
                 public dialog: MatDialog,
                 private sanitizer: DomSanitizer   ) { 

    }


    /**
     * Login - STEP 1
     * Post client generated SECRET KEY encrypted with DH server PUBLIC KEY
     * @param dhPublicKey 
     */
     public getSecretKeyEncrypted( dhPublicKey : DhPublicKey ): DhSecretKey {

        // Generate DH secret key
        let dhSecretKeyBase64 = this.cryptoService.generateSecretKeyBase64();
        //console.log ( "dhSecretKeyBase64", dhSecretKeyBase64 );
        // Store it in localStorage
        this.cryptoService.setSecureStorageItem( StorageParams.DH_SECRET_KEY, dhSecretKeyBase64 );
        // Encrypt with DH server PUBLIC Key
        let dhSecretKeyEncryptedBase64 = this.cryptoService.encryptWitPublicKey( dhSecretKeyBase64, dhPublicKey.publicKeybase64 );
        // Prepare request body with encrypted secret key
        let dhSecretKey = new DhSecretKey( dhSecretKeyEncryptedBase64 );
        // Send encrypted secret key to server
     
        return dhSecretKey;
     }



     /**
      * Login - STEP 2
      * Post credentials encrypted with DH - SECRET KEY
      * @param loginRequest 
      */
     public encryptLoginRequest(loginRequest: LoginRequest): any {

          // Encrypt
          let loginNameEncrypted = this.cryptoService.encryptWithDH( this.sanitize( loginRequest.loginName ));
          let passwordEncrypted = this.cryptoService.encryptWithDH( this.sanitize( loginRequest.password ));
  
          return new LoginRequest (loginNameEncrypted, passwordEncrypted); 
     }


     
     /**
      * Encrypt request body with DH
      * @param body 
      */
     public encryptBodyJsonDh(body: any): String {
          return this.cryptoService.encryptWithDH( body );        
     }


     /**
      * Decrypt response body with DH
      * @param bodyEncrypted 
      */
     public decryptBodyJsonDh(bodyEncrypted: any): any {         
          let data = this.cryptoService.decryptWithDH( bodyEncrypted );
          let dataObject = JSON.parse ( data.toString() );     
          //console.log(  "decryptBodyDh : ", dataObject )
          return dataObject;
     }



     public sanitizeRegister( register : RegisterFormData ){
          register.loginName = this.sanitize( register.loginName );
          register.password = this.sanitize( register.password );
          register.confirmPassword = this.sanitize( register.confirmPassword );
          register.languageId = this.sanitize( register.languageId );
          register.genderId = this.sanitize( register.genderId );
          return register;
     }

     /**
      * Use Angular dom sanitizer to clean data / XSS
      * @param data 
      */
     public sanitize( data : string ) : string {
          return this.sanitizer.sanitize( SecurityContext.NONE, data );
     }


     /**
      * Use Angular dom sanitizer to clean data / XSS
      * @param data 
      */
     public sanitizeHtml( data : string ) : string {
          return this.sanitizer.sanitize( SecurityContext.HTML, data );
     }




      /**
      * Encrypt file content and name
      * @param file 
      */
     public async encryptFileWithDH ( file: File ) {

          let result : File = await new Promise((resolve) => {
               if (!file) {
                    resolve(file);
                } 
                var reader = new FileReader();
                reader.onload = (e) => {
  
                    let encryptedContent = this.cryptoService.encryptWithDH( reader.result );
                    let encryptedName = this.cryptoService.encryptWithDH( file.name ).toString();                 
                    let fileOut = new File( [encryptedContent], encryptedName, { type : file.type });
  
                    resolve(fileOut);  
                };
    
                reader.readAsArrayBuffer(file);
          });

          return result;
      }

} 