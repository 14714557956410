import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { UsbConstants } from '../constants/UsbConstants';

@Pipe({ name: 'title' })
export class TitlePipe implements PipeTransform {

  constructor(private translate:  TranslateService) {

  }



  transform(title: any, args?: any): any {
       //console.log ( "getTitleContent " + title )
        if ( title.includes( UsbConstants.LABELS_PREFIX ) ){
            return this.translate.get( title );
        }else if ( title.includes( UsbConstants.STORAGE_PREFIX )){
            return of(sessionStorage.getItem( title.replace( UsbConstants.STORAGE_PREFIX, ""))) ; 
        }else{
            return of(title);
        }  
  }

} 