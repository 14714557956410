<div>
  <div class="text-center ">
    <h2 mat-dialog-title class="m-b-20" >{{ dataParams.title | translate }}</h2>
  </div>
  <div>
    <div fxLayoutAlign="center" *ngIf="dataParams.success" >
        <img src="assets/images/success.png" class="success-image" >
    </div>

    <div fxLayoutAlign="center" *ngIf="dataParams.failed" >
        <img src="assets/images/forbidden.png" class="success-image" >
    </div>
  </div>

  <div  *ngIf="dataParams.message" >
    <div class="text-center">
      <div class="m-all-20">{{ dataParams.message | translate }}</div>
    </div>
    <div class="text-center" *ngIf="dataParams.infos">
      <div class="m-all-20">{{ dataParams.infos  }}</div>
    </div>
    <div fxLayout="row wrap" >
        <div class="p-10" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" >
          <mat-dialog-actions >
            <button mat-raised-button class="btn-block btn-lg" color="primary"  (click)="onClickYes()">{{ 'com.usafebox.button.ok' | translate }}</button>
          </mat-dialog-actions>
        </div>
    </div>
  </div> 
  
  <div  *ngIf="dataParams.question" >
    <div class="text-center">
      <div class="m-all-20">{{ dataParams.question | translate }}</div>
    </div>
    <div class="text-center" *ngIf="dataParams.infos">
      <div class="m-all-20">{{ dataParams.infos  }}</div>
    </div>
    <div fxLayout="row wrap" >
        <div class="p-10" fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100" >
          <mat-dialog-actions >
            <button mat-raised-button class="btn-block btn-lg" color="primary"  (click)="onClickYes()">{{ 'com.usafebox.button.yes' | translate }}</button>
          </mat-dialog-actions>
        </div>
        <div class="p-10" fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100" >
          <mat-dialog-actions >
            <button mat-raised-button class="btn-block btn-lg" (click)="onClickNo()">{{ 'com.usafebox.button.no' | translate }}</button>
          </mat-dialog-actions >
        </div>
    </div>
  </div>
</div>



