import { Component, OnInit, Inject, Optional, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'encryption-modal-content',
    templateUrl: './encryption-modal-content.html',
})
export class EncryptionModalContent implements OnInit {


    constructor( ) {

    }


    ngOnInit(): void {

    }


}