import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confirmation-modal-content',
    templateUrl: './confirmation-modal-content.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalContent implements OnInit {

    public dataParams : any;

    constructor( public dialogRef: MatDialogRef<ConfirmationModalContent>,
                 @Optional() @Inject(MAT_DIALOG_DATA) public data: any  ) {

        this.dataParams = { ...data };

    }


    ngOnInit(): void {

    }


    public onClickYes( ) {
        this.dialogRef.close({ event: "yes" });
        return false;
    }

    public onClickNo( ) {
        this.dialogRef.close({ event: "no" });
        return false;
    }

}