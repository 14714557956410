import { Routes } from '@angular/router';

import { MainComponent } from './layouts/main/main.component';
import { AppBlankComponent } from './layouts/blank/blank.component';

export const AppRoutes: Routes = [
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: '',
                redirectTo: '/authentication/login',
                pathMatch: 'full'
            }
            ,
            {
                path: 'dashboard',
                loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
            }
            ,
            {
                path: 'spaces',
                loadChildren: () => import('./modules/spaces/spaces.module').then(m => m.SpacesModule)
            }
            ,
            {
                path: 'safes',
                loadChildren: () => import('./modules/safes/safes.module').then(m => m.SafesModule)
            }
            ,
            {
                path: 'categories',
                loadChildren: () => import('./modules/categories/categories.module').then(m => m.CategoriesModule)
            }
            ,
            {
                path: 'deposits',
                loadChildren: () => import('./modules/deposits/deposits.module').then(m => m.DepositsModule)
            }
            ,
            {
                path: 'deposit',
                loadChildren: () => import('./modules/deposit/deposit.module').then(m => m.DepositModule)
            }
            ,
            {
                path: 'contacts',
                loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule)
            }
            ,
            {
                path: 'profile',
                loadChildren: () => import( './modules/user-profile/user-profile.module').then(m => m.UserProfileModule)
            }
            ,
            {
                path: 'support',
                loadChildren: () => import( './modules/support/support.module').then(m => m.SupportModule)
            }
        ]
    }
    ,
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'error',
                loadChildren: () => import('./modules/errors/errors.module').then(m => m.ErrorsModule)
            }
            ,
            {
                path: 'authentication',
                loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
            }
        ]
    }
    ,
    {
        path: '**',
        redirectTo: 'error/not-found'
    }
];
