<div class="main-page" *ngIf="userProfile" >
    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div  [dir]="userSettings.dir"  [ngClass]="{ 'main-container' : !userSettings.fullScreen,  'main-container-fullscreen' : userSettings.fullScreen, 'minisidebar': userSettings.minisidebar, 'danger': userSettings.danger, 'blue': userSettings.blue, 'green': userSettings.green, 'dark': userSettings.dark }">
        <!-- ============================================================== -->
        <!-- Topbar - style you can find in header.scss -->
        <!-- ============================================================== -->
        <mat-toolbar color="primary" class="topbar telative">
            <!-- ============================================================== -->
            <!-- Logo - style you can find in header.scss -->
            <!-- ============================================================== -->


        <!-- 
            <div class="navbar-header">
                <a class="navbar-brand" href="/">
                    <span fxShow="false" fxShow.gt-xs>
                        <img src="assets/images/logo-web-transparent.png" alt="homepage" class="dark-logo" height="60" >
                        <img src="assets/images/logo-web-transparent-white.png" class="light-logo" alt="homepage" height="60" >
                    </span>
                </a>
            </div> 
        -->

            <!-- ============================================================== -->
            <!-- sidebar toggle -->
            <!-- ============================================================== -->

            <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
                <mat-icon>menu</mat-icon>
            </button>
            <!-- ============================================================== -->
            <!-- Search - style you can find in header.scss -->
            <!-- ============================================================== -->

 <!--         
            <button mat-icon-button class="srh-btn" (click)="showSearch = !showSearch">
                <mat-icon>search</mat-icon>
            </button>

-->  

            <form class="app-search" [ngClass]="{'show-search': showSearch}">
                <input type="text" class="form-control" placeholder="Search &amp; enter">
                <a class="cl-srh-btn" (click)="showSearch = !showSearch">
                    <i class="ti-close"></i>
                </a>
            </form>
            <span fxFlex></span>

            <!-- ============================================================== -->
            <!-- app header component - style you can find in header.scss / header.component.ts-->
            <!-- ============================================================== -->
            <app-header></app-header>
            <!-- ============================================================== -->
            <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
            <!-- ============================================================== -->
            <button (click)="end.toggle()" mat-icon-button class="ml-xs overflow-visible">
                <mat-icon>settings</mat-icon>
            </button>
        </mat-toolbar>
        <!-- ============================================================== -->
        <!-- End Topbar - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
            [ngClass]="{'minisidebar': userSettings.minisidebar}">
            <!-- ============================================================== -->
            <!-- Sidebar - style you can find in sidebar.scss -->
            <!-- ============================================================== -->
            <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
                [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true" (close)="sidebarOpened = false"
                [perfectScrollbar]="config" (click)="closeSideNav( snav )">

                <app-sidebar [minisidebar]="userSettings.minisidebar" class="app-sidebar"></app-sidebar>

            </mat-sidenav>
            <!-- ============================================================== -->
            <!-- Sidebar - style you can find in sidebar.scss -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
            <!-- ============================================================== -->
            <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
                <div class="scroll">
                    <mat-nav-list>
                        <h3 mat-subheader class="text-uppercase font-weight-bold">{{ 'com.usafebox.settings.title' | translate}}</h3>
                        <mat-list-item>
                            <mat-slide-toggle color="warn" [(ngModel)]="userSettings.fullScreen" (change)="saveUserSettings()">{{ 'com.usafebox.settings.fullscreen' | translate}}</mat-slide-toggle>
                        </mat-list-item>
                        <mat-list-item>
                            <mat-slide-toggle color="warn" (change)="userSettings.dir = (userSettings.dir == 'rtl' ? 'ltr' : 'rtl');saveUserSettings()">{{ 'com.usafebox.settings.rtl' | translate}}</mat-slide-toggle>
                        </mat-list-item>
                        <mat-list-item>
                            <mat-slide-toggle [(ngModel)]="userSettings.minisidebar" (change)="saveUserSettings()" >{{ 'com.usafebox.settings.minisidebar' | translate}}</mat-slide-toggle>
                         </mat-list-item>
                        <mat-divider></mat-divider>
                        <h3 mat-subheader class="text-uppercase font-weight-bold">{{ 'com.usafebox.settings.colors' | translate}}</h3>
                        <mat-list-item>
                            <mat-checkbox color="warn" [(ngModel)]="userSettings.danger" (change)="userSettings.green = userSettings.blue = false;saveUserSettings()" class="text-danger">{{ 'com.usafebox.settings.red' | translate}}</mat-checkbox>
                        </mat-list-item>
                        <mat-list-item>
                            <mat-checkbox color="warn" [(ngModel)]="userSettings.green" (change)="userSettings.danger = userSettings.blue = false;saveUserSettings()" class="text-megna">{{ 'com.usafebox.settings.green' | translate}}</mat-checkbox>
                        </mat-list-item>
                        <mat-list-item>
                            <mat-checkbox color="warn" [(ngModel)]="userSettings.blue" (change)="userSettings.green = userSettings.danger = false;saveUserSettings()" class="text-info">{{ 'com.usafebox.settings.blue' | translate}}</mat-checkbox>
                        </mat-list-item>
                        <mat-list-item>
                            <mat-checkbox color="warn" [(ngModel)]="userSettings.dark" (change)="userSettings.green = userSettings.blue = userSettings.danger = false;saveUserSettings()">{{ 'com.usafebox.settings.dark' | translate}}</mat-checkbox>
                        </mat-list-item>						
                    </mat-nav-list>
                </div>
            </mat-sidenav>

            <!-- ============================================================== -->
            <!-- Page container - style you can find in pages.scss -->
            <!-- ============================================================== -->
            <mat-sidenav-content [ngClass]="{ 'page-wrapper' : !userSettings.fullScreen,  'page-wrapper-fullscreen' : userSettings.fullScreen}" 
                                 [perfectScrollbar]="config" >              
                    <app-breadcrumb></app-breadcrumb>
                    <div class="page-content" >
                        <router-outlet >
                        </router-outlet>
                    </div>                
            </mat-sidenav-content>
            <!-- ============================================================== -->
            <!-- Page container - style you can find in pages.scss -->
            <!-- ============================================================== -->
        </mat-sidenav-container>
    </div>
</div>