  export class UserSettings {
    constructor( 
      public dir: string,
      public danger: boolean,
      public green: boolean,
      public blue: boolean,
      public dark: boolean,
      public minisidebar: boolean,
      public fullScreen: boolean  ){}
  }
