import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoginRequest } from '../../models/LoginRequest';
import { map } from 'rxjs/operators';
import { EncryptionService } from '../encryption/encryption.service';
import { DhPublicKey } from '../../models/DhPublicKey';
import { DhNegociation } from '../../models/DhNegociation';
import { UsbConstants } from '../../constants/UsbConstants';
import { Login } from '../../models/Login';
import { Avatar } from '../../models/Avatar';
import { Configuration } from '../../models/Configuration';
import { Language } from '../../models/Language';
import { MatDialog } from '@angular/material/dialog';


@Injectable()
export class GatewayApiService {

    private baseUrl = environment.gatewayUrl;

    private headers = new HttpHeaders( );


    constructor( public http:HttpClient,
                 private encryptionService:EncryptionService,
                 public dialog: MatDialog  ) { 

        // When you use this header, DTE is enabled ( DH )
        this.headers = this.headers.set( UsbConstants.HEADER_DTE, UsbConstants.DTE_JSON);
    }

    


/*================================== Login ====================================================================*/


    /**
     * Get server PUBLIC KEY to start DH negociation
     */
    public getDhPublicKey() : Observable<DhPublicKey>{
        return this.http.get<DhPublicKey>(`${this.baseUrl}/dh`).pipe(
            map(data => {
                return data;
            })
        );
    }

    /**
     * Post client generated SECRET KEY encrypted with DH server PUBLIC KEY
     * @param dhPublicKey 
     */
    public postSecretkey( dhPublicKey : DhPublicKey ) : Observable<DhNegociation>{
        return this.http.post<DhNegociation>(`${this.baseUrl}/dh`, this.encryptionService.getSecretKeyEncrypted(dhPublicKey)).pipe(
            map(data => {
                return data;
            })
        );
    }

    /**
     * Post credentials double encryption ( fiels + body with DH - SECRET KEY ) 
     * @param loginRequest
     */
    public postLogin( loginRequest : LoginRequest  ) : Observable<Login>{
        return this.http.post<Login>(`${this.baseUrl}/login`, loginRequest, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }
   

    /**
     * Check user is logged ( valid session )
     * No DH because, if the keys are losts from session storage > unable to decrypt response
     */
    public logged() : Observable<boolean>{
        return this.http.get<boolean>(`${this.baseUrl}/logged`).pipe(
            map(data => {
                return data;
            })
        );
    }   



    /**
     * Invalidate session on server
     *  No DH because, if the keys are losts from session storage > unable to decrypt response
     */
    public logout() : Observable<boolean>{
        return this.http.get<boolean>(`${this.baseUrl}/logout` ).pipe(
            map(data => {
                return data;
            })
        );
    }


/*=============== CONFIG ==============================================================================================*/



    public getConfiguration(): Observable<Configuration>{              
        return this.http.get<Configuration>( `${this.baseUrl}/configuration`).pipe(
            map(data => {          
                return data;
            })
        );
    }


/*=============== AVATARS ==============================================================================================*/


    public getAvatars( ): Observable<Avatar[]>{               
        return this.http.get<Avatar[]>( `${this.baseUrl}/static/images/avatars/index.json`).pipe(
            map(data => {           
                return data;
            })
        );
    }


    public getAvatarContent( avatarId: string ) {
        return `${this.baseUrl}/static/images/avatars/${avatarId}`
    }


/*=============== LANGUAGES ==============================================================================================*/

    public getLanguages( ): Observable<Language[]>{               
        return this.http.get<Language[]>( `${this.baseUrl}/language`).pipe(
            map(data => {           
                return data;
            })
        );
    }






} 