import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    ViewChild,
    HostListener,
    Directive,
    AfterViewInit,
    Input,
    OnInit
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';

import { UsersBusinessService } from '../../../services/business/users.business.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { MediaConstants } from '../../../constants/MediaConstants';
import { SharedService } from '../../../services/shared.service';
import { SafesApiService } from '../../../services/api/safes.api.service';
import { Indicators } from '../../../models/Indicators';
import { UserProfile } from '../../../models/UserProfile';
import { Deposit } from '../../../models/Deposit';
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: []
})
export class AppSidebarComponent implements OnDestroy, OnInit {

    @Input() public minisidebar: Boolean; 
    public config: PerfectScrollbarConfigInterface = {};
    public mobileQuery: MediaQueryList;

    private _mobileQueryListener: () => void;
    public status: boolean = true;

    public itemSelect: number[] = []
    public parentIndex: Number;
    public childIndex: Number;
    public indicators : Indicators;
    public userProfile : UserProfile;




    constructor( private changeDetectorRef: ChangeDetectorRef,
                 private media: MediaMatcher,
                 private sharedService : SharedService,
                 private safesApiService : SafesApiService,
                 private usersBusinessService : UsersBusinessService ) {

	    this.mobileQuery = this.media.matchMedia( MediaConstants.MIN_WIDTH );
        this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
        this.mobileQuery.addEventListener ('change', this._mobileQueryListener);

    }


    ngOnInit(): void {

        this.sharedService.onDepositUnreadView.subscribe((data:Deposit) => {
            if ( data != null ){
               this.loadIndicators();
            }
        });

        this.sharedService.onProfileLoaded.subscribe((data:UserProfile) => {
            this.userProfile = data;
        });

        this.loadIndicators();
    }


    /**
     * Load indicators
     */
    private loadIndicators(){
        this.safesApiService.getIndicators().subscribe( data=>{
            this.indicators = data;
            this.sharedService.emitIndicatorsLoaded(data);
        });
    }

    /**
     * Mobile event listener remove
     */
    ngOnDestroy(): void {
        this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    }

    logout(){
        this.usersBusinessService.logout()
    }


    setClickedRow(i, j) {
        this.parentIndex = i;
        this.childIndex = j;
    }
    subclickEvent() {
        this.status = true;
    }


    scrollToTop() {
        this.sharedService.scrollToTop();
    }


}
