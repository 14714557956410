import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MainComponent } from './layouts/main/main.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/main/header/header.component';
import { AppSidebarComponent } from './layouts/main/sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './layouts/main/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SharedModule } from './shared/shared.module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { UsersApiService } from './services/api/users.api.service';
import { UsersBusinessService } from './services/business/users.business.service';
import { SafesApiService } from './services/api/safes.api.service';
import { SafesBusinessService } from './services/business/safes.business.service';
import { AppInterceptor } from './interceptor/app-interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from  '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from './services/business/configuration.service';
import { SnackbarService } from './services/snackbar.service';
import { SharedService } from './services/shared.service';
import { CommunicationsApiService } from './services/api/communications.api.service';
import { CryptoService } from './services/crypto.service';
import { EncryptionService } from './services/encryption/encryption.service';
import { CustomModule } from './custom-module';
import { EncryptionModalContent } from './modals/encryption-modal/encryption-modal.component';
import { TransfertModalContent } from './modals/transfert-modal/transfert-modal.component';
import { GatewayApiService } from './services/api/gateway.api.service';
import { SauthApiService } from './services/api/sauth.api.service';
import { ConfirmationModalContent } from './modals/confirmation-modal/confirmation-modal.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};


export function initConfig(configurationService: ConfigurationService) {
  return () => configurationService.loadConfig();
}


// loader module
export  function  HttpLoaderFactory(http:  HttpClient) {
  return  new  TranslateHttpLoader(http, `${environment.gatewayUrl}/static/i18n/json/`, `.json`);
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    AppHeaderComponent,
    AppBlankComponent,
    AppSidebarComponent,
    AppBreadcrumbComponent,
    EncryptionModalContent,
    ConfirmationModalContent,
    TransfertModalContent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    HttpClientModule,
    SharedModule,
    NgxSpinnerModule,
    CustomModule,
    NgMultiSelectDropDownModule.forRoot(),

    RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' }),


    //RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' }),
    //RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' ,  scrollOffset: [0, 0], scrollPositionRestoration: 'top' }),

   // RouterModule.forRoot(AppRoutes),


    TranslateModule.forRoot({
      loader: {
        provide:  TranslateLoader,
        useFactory:  HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: HTTP_INTERCEPTORS, 
      useClass: AppInterceptor, 
      multi: true 
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [ConfigurationService],
      multi: true
    },
    SnackbarService,
    GatewayApiService,
    UsersApiService,
    SauthApiService,
    UsersBusinessService,
    SafesApiService,
    SafesBusinessService,
    TranslateService,
    ConfigurationService,
    SharedService,
    CommunicationsApiService,
    CryptoService,
    EncryptionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
