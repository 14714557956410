import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';



@Injectable()
export class CommunicationsApiService {

    private baseUrl = environment.gatewayUrl + "/communications/api/v1";


    constructor( public http:HttpClient,
                 private spinner: NgxSpinnerService    ) { 

    }




    
} 