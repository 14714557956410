import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Contact } from '../models/Contact';
import { Deposit } from '../models/Deposit';
import { Indicators } from '../models/Indicators';
import { UserProfile } from '../models/UserProfile';

@Injectable()
export class SharedService{


    //@Output() onProfileLoaded: EventEmitter<any> = new EventEmitter();
    //@Output() onIndicatorsLoaded: EventEmitter<any> = new EventEmitter();
    //@Output() onDepositUnreadView: EventEmitter<any> = new EventEmitter();
  //@Output() onContactConfirm: EventEmitter<any> = new EventEmitter();

    public onProfileLoaded: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);
    public onIndicatorsLoaded: BehaviorSubject<Indicators> = new BehaviorSubject<Indicators>(null);
    public onDepositUnreadView: BehaviorSubject<Deposit> = new BehaviorSubject<Deposit>(null);
    public onContactConfirm: BehaviorSubject<Contact> = new BehaviorSubject<Contact>(null);


    constructor(   ){

    }


    /**
     * Scroll to top > on page
     */
    public scrollToTop() {
        let pageWrapper = document.querySelector('.page-wrapper');
        if ( pageWrapper == null ){
            pageWrapper = document.querySelector('.page-wrapper-fullscreen');
        }
        if ( pageWrapper != null){
             pageWrapper.scroll({
                top: 0,
                left: 0
            });
        }
    }

    /**
     * Emit profile change ( after load + decryption )
     * @param data 
     */
    public emitUserProfileLoaded(data: UserProfile) {
        this.onProfileLoaded.next(data);
    }


    /**
     * 
     * @param data 
     */
    public emitIndicatorsLoaded(data: Indicators) {
        this.onIndicatorsLoaded.next(data);
    }


        /**
     * 
     * @param data 
     */
    public emitUnreadDepositView(data: Deposit) {
        this.onDepositUnreadView.next( data );
    }


     /**
     * 
     * @param data 
     */
    public emitOnContactConfirm() {
        this.onContactConfirm.next(null);
    }



}