import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable()
export class SnackbarService{


    private SUCCESS_PANELS = [ 'success' ];
    private WARN_PANELS = [ 'warning' ];
    private ERROR_PANELS = [ 'error' ];

    constructor( private translate:  TranslateService,
                 private snackBar: MatSnackBar ){


    }


    /**
     * Show SUCCESS 
     * @param messageKey is the key used on json file fro translation
     * @param objectData is the data that could be bind on the translation
     */
    public showSnackBarSuccesTranslationWithData( messageKey : string, objectData : Object){
        this.translate.get( messageKey, objectData).subscribe(data => {          
           this.snackBar.open(data, '×', { panelClass: this.SUCCESS_PANELS, verticalPosition: 'top', duration: 3000 });
        });
    }


    
    /**
     * Show WARNING 
     * @param messageKey is the key used on json file fro translation
     * @param objectData is the data that could be bind on the translation
     */
    public showSnackBarWarningTranslationWithData( messageKey : string, objectData : Object){
        this.translate.get( messageKey, objectData).subscribe(data => {          
           this.snackBar.open(data, '×', { panelClass: this.WARN_PANELS, verticalPosition: 'top', duration: 5000 });
        });
    }


    
    /**
     * Show ERROR 
     * @param messageKey is the key used on json file fro translation
     * @param objectData is the data that could be bind on the translation
     */
    public showSnackBarErrorTranslationWithData( messageKey : string, objectData : Object){
        this.translate.get( messageKey, objectData).subscribe(data => {          
           this.snackBar.open(data, '×', { panelClass: this.ERROR_PANELS, verticalPosition: 'top', duration: 5000 });
        });
    }



    /**
     * Show SUCCESS 
     * @param messageKey is the key used on json file fro translation
     */
    public showSnackBarSuccesTranslation( messageKey : string ){
        this.translate.get( messageKey).subscribe(data => {          
           this.snackBar.open(data, '×', { panelClass: this.SUCCESS_PANELS, verticalPosition: 'top', duration: 3000 });
        });
    }


    /**
     * Show WARNING 
     * @param messageKey is the key used on json file fro translation
     */
    public showSnackBarWarningTranslation( messageKey : string ){
        this.translate.get( messageKey).subscribe(data => {          
           this.snackBar.open(data, '×', { panelClass: this.WARN_PANELS, verticalPosition: 'top', duration: 5000 });
        });
    }


    /**
     * Show ERROR 
     * @param messageKey is the key used on json file fro translation
     */
    public showSnackBarErrorTranslation( messageKey : string ){
        this.translate.get( messageKey).subscribe(data => {          
           this.snackBar.open(data, '×', { panelClass: this.ERROR_PANELS, verticalPosition: 'top', duration: 5000 });
        });
    }


    /**
     * Show SUCCESS 
     * @param message is the message already translated
     */
    public showSnackBarSuccessMessage( message : string ){       
        this.snackBar.open( message, '×', { panelClass: this.SUCCESS_PANELS, verticalPosition: 'top', duration: 3000 });
    }


     /**
     * Show WARNING 
     * @param message is the message already translated
     */
    public showSnackBarWarningMessage( message : string ){       
        this.snackBar.open( message, '×', { panelClass: this.WARN_PANELS, verticalPosition: 'top', duration: 5000 });
    }


    /**
     * Show ERROR 
     * @param message is the message already translated
     */
    public showSnackBarErrorMessage( message : string ){       
        this.snackBar.open( message, '×', { panelClass: this.ERROR_PANELS, verticalPosition: 'top', duration: 5000 });
    }


    /**
     * Show SUCCESS LIST
     * @param messageS are the messages ( list ) already translated
     */
    public showSnackBarSuccessMessages( messages : Array<string> ){    
        let messagesConcat = "";
        messages.forEach(message => {
            messagesConcat += message + "\n";
        });
        this.snackBar.open( messagesConcat, '×', { panelClass: this.SUCCESS_PANELS, verticalPosition: 'top', duration: 30000 });
    }



    /**
     * Show WARNING LIST
     * @param messageS are the messages ( list ) already translated
     */
    public showSnackBarWarningMessages( messages : Array<string> ){    
        let messagesConcat = "";
        messages.forEach(message => {
            messagesConcat += message + "\n";
        });
        this.snackBar.open( messagesConcat, '×', { panelClass: this.WARN_PANELS, verticalPosition: 'top', duration: 30000 });
    }



    /**
     * Show ERROR LIST
     * @param messageS are the messages ( list ) already translated
     */
    public showSnackBarErrorMessages( messages : Array<string> ){    
        let messagesConcat = "";
        messages.forEach(message => {
            messagesConcat += message + "\n";
        });
        this.snackBar.open( messagesConcat, '×', { panelClass: this.ERROR_PANELS, verticalPosition: 'top', duration: 30000 });
    }
}