import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Errors } from '../constants/Errors';
import { StorageParams } from '../constants/StorageParams';
import { MatDialog } from '@angular/material/dialog';
import { EncryptionService } from '../services/encryption/encryption.service';
import { UsbConstants } from '../constants/UsbConstants';

@Injectable()
export class AppInterceptor implements HttpInterceptor {


    constructor( private spinner: NgxSpinnerService,
                 private router:Router,
                 public dialog: MatDialog,
                 private encryptionService : EncryptionService ) {
                  
    }
  
    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // console.log ( "HttpInterceptor REQUEST METHOD " + req.method );

        // Request body could be encrypted with DH - DTE if specific header is present
        if ( req.headers.has(UsbConstants.HEADER_DTE) && ( req.method === 'POST' || req.method === 'PUT' )){
            if (req.headers.get(UsbConstants.HEADER_DTE) === UsbConstants.DTE_JSON) {
                console.log ( "HttpInterceptor REQUEST BODY ", req.body );
                req = req.clone({
                  body : { encryptedBody : this.encryptionService.encryptBodyJsonDh( JSON.stringify(req.body) ) }
                });
            }
        }
        

        // Crsf must be present in every requests
        let csrf = sessionStorage.getItem( StorageParams.CSRF );
        if ( csrf != null ){
            req = req.clone({
              setHeaders: {
                'USB-Csrf': csrf
              }
            });
        }

        // Optionnal : this spinner could be disabled with request parameter 'spinner'
        let spinnerParam = req.params.get("spinner");
        if ( spinnerParam == null || spinnerParam === 'true' ){
             this.spinner.show();
        }


        return next.handle(req).pipe(map((event: HttpEvent<any>) => { // Warning > do not use 'tap' instead of 'map', else body decryption not working !

            if (event instanceof HttpResponse) {

               //console.log ( "HttpInterceptor RESPONSE ", event.body  );

               // Response body could be decrypted with DH - DTE if specific header is present
               if ( event.ok && event.headers.has(UsbConstants.HEADER_DTE)){
                    event =  event.clone({
                      body : this.encryptionService.decryptBodyJsonDh( event.body.encryptedBody )
                    });
               }

              this.spinner.hide();
            }
            return event;
          }),

          catchError((error: HttpErrorResponse) => {

            this.spinner.hide();
            this.dialog.closeAll();

            console.log ( "Interceptor catchError ", error);
            
            // Session expired
            if ( error.status === 401 || ( error.error && error.error.code === 4060 )){
                 this.router.navigate(['/authentication/login' ]);
            }

            return throwError(error);
          })
        );

    }  
}