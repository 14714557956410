import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { StorageParams } from "../../constants/StorageParams";
import { Configuration } from "../../models/Configuration";
import { GatewayApiService } from "../api/gateway.api.service";


@Injectable({
    providedIn: 'root'
})
export class ConfigurationService  {


    private config : Configuration; 


    constructor( private gatewayApiService : GatewayApiService ) {


    }

    

    public loadConfig() {
        this.gatewayApiService.getConfiguration().subscribe(config => { 
            sessionStorage.setItem( StorageParams.CONFIG, JSON.stringify(config));
            this.config = config;  
        }, error => {
            console.log( error );    
        })
    }


    public getConfig() : Configuration {
        if ( this.config ){
            return this.config;
        }
        return JSON.parse( sessionStorage.getItem( StorageParams.CONFIG ));
    }
    


}

