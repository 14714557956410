import { Pipe, PipeTransform } from '@angular/core';
import { GatewayApiService } from '../services/api/gateway.api.service';
import { SafesApiService } from '../services/api/safes.api.service';


@Pipe({ name: 'imageurl' })
export class ImageUrlPipe implements PipeTransform {

  constructor( private gatewayApiService: GatewayApiService  ) {

  }



  public transform(id: string, type: string): any {
      switch (type) {
        case 'avatar': return this.gatewayApiService.getAvatarContent( id );

			  default: throw new Error(`Invalid image type specified: ${type}`);
	    }
  }



} 