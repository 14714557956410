


export class UsbConstants {

    static GENDERS = [ "M", "F" ];


    static LABELS_PREFIX  = "com.usafebox."
    static STORAGE_PREFIX = "storage.session.";


    static HEADER_DTE = "USB-Dte";

    static MODAL_FILE_SIZE_MIN = 5 * 1024 * 1024; // 5 MB


    static DTE_JSON = "1";
    static DTE_MULTIPART = "2";

}
