<!-- ============================================================== -->
<!-- Notification - style you can find in header.scss -->
<!-- ============================================================== -->




<button [matMenuTriggerFor]="notification" mat-icon-button class="m-r-10" *ngIf="indicators&&userProfile" >
    <mat-icon>message</mat-icon>
    <div class="notify" *ngIf="indicators.unreadDepositsCount>0">
        <span class="heartbit"></span>
        <span class="point"></span>
    </div>
</button>
<mat-menu #notification="matMenu" class="mymegamenu">
    <div class="mailbox">
        <ul>
            <li>
                <div class="drop-title">Notifications</div>
            </li>
            <li>
                <div class="message-center notifications" *ngIf="indicators&&userProfile" >
                    <a *ngIf="indicators.unreadDepositsCount>0" [routerLink]="['/spaces']">
                        <div class="round round-success">
                            <i class="ti-link"></i>
                        </div>
                        <div class="mail-content">
                            <span class="mail-desc" *ngIf="indicators.unreadDepositsCount==1">{{ 'com.usafebox.notifications.you.have' | translate}} {{indicators.unreadDepositsCount}} {{ 'com.usafebox.notifications.unread.deposit' | translate}}</span>
                            <span class="mail-desc" *ngIf="indicators.unreadDepositsCount>1" >{{ 'com.usafebox.notifications.you.have' | translate}} {{indicators.unreadDepositsCount}} {{ 'com.usafebox.notifications.unread.deposits' | translate}}</span>
                        </div>
                    </a>
                    <a *ngIf="userProfile.unConfirmedContactsCount>0" [routerLink]="['/contacts']">
                        <div class="round round-success">
                            <i class="ti-user"></i>
                        </div>
                        <div class="mail-content">
                            <span class="mail-desc" *ngIf="userProfile.unConfirmedContactsCount==1">{{ 'com.usafebox.notifications.you.have' | translate}} {{userProfile.unConfirmedContactsCount}} {{ 'com.usafebox.notifications.invitation' | translate}}</span>
                            <span class="mail-desc" *ngIf="userProfile.unConfirmedContactsCount>1" >{{ 'com.usafebox.notifications.you.have' | translate}} {{userProfile.unConfirmedContactsCount}} {{ 'com.usafebox.notifications.invitations' | translate}}</span>
                        </div>
                    </a>
                </div>
            </li>

        </ul>
    </div>
</mat-menu>

<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<span *ngIf="userProfile">
    <span> {{userProfile.firstName}} {{userProfile.lastName}} </span>
    <button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
        <img *ngIf="userProfile.avatarId" [src]="userProfile.avatarId | imageurl : 'avatar' " alt="user" class="profile-pic">     
    </button>
</span>
<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item  routerLink="/profile/edit" >
        <mat-icon>account_box</mat-icon>{{ 'com.usafebox.header.profil' | translate}}</button>
    <button mat-menu-item (click)="logout()" >
        <mat-icon>exit_to_app</mat-icon>{{ 'com.usafebox.header.logout' | translate}}</button>
</mat-menu>