<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div>
    <!-- Logo image -->
    <div>
        <img  *ngIf="!minisidebar" src="assets/images/logo-web-transparent.png" alt="side" width="299"  class="side-logo-top-full"> 
        <img  *ngIf="minisidebar" src="assets/images/logo-web-small-transparent.png" alt="side" width="50" class="side-logo-top-small" > 
        <img  *ngIf="minisidebar" src="assets/images/logo-web-transparent.png" alt="side" width="299"  class="side-logo-top-full"> 
    </div>
</div>

<mat-nav-list appAccordion>
    <mat-list-item appAccordionLink routerLinkActive="selected" group="dashboard" (click)="scrollToTop()">       
        <a class="" appAccordionToggle [routerLink]="['/dashboard']" >
            <mat-icon>av_timer</mat-icon>
            <span>{{ 'com.usafebox.menu.dashboard' | translate }}</span>
            <span fxFlex></span>
        </a>
    </mat-list-item>
    <mat-list-item appAccordionLink routerLinkActive="selected" group="spaces" (click)="scrollToTop()">       
        <a class="" appAccordionToggle [routerLink]="['/spaces']" >
            <mat-icon>security</mat-icon>
            <span>{{ 'com.usafebox.menu.spaces' | translate }}</span>
            <span fxFlex></span>
            <span *ngIf="indicators&&indicators.unreadDepositsCount>0" class="label label-red"><span class="heartbit"></span>{{indicators.unreadDepositsCount}}</span>
        </a>
    </mat-list-item>
    <mat-list-item appAccordionLink routerLinkActive="selected" group="contacts" (click)="scrollToTop()">       
        <a class="" appAccordionToggle [routerLink]="['/contacts']" >
            <mat-icon>contacts</mat-icon>
            <span>{{ 'com.usafebox.menu.contacts' | translate }}</span>
            <span fxFlex></span>
            <span *ngIf="userProfile&&userProfile.unConfirmedContactsCount>0" class="label label-red">{{userProfile.unConfirmedContactsCount}}</span>
        </a>
    </mat-list-item>
    <mat-list-item appAccordionLink routerLinkActive="selected" group="profile" (click)="scrollToTop()">       
        <a class="" appAccordionToggle href="javascript:;" >
            <mat-icon>account_box</mat-icon>
            <span>{{ 'com.usafebox.menu.profile' | translate }}</span>
            <span fxFlex></span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>
        <mat-nav-list class="sub-item" >
            <mat-list-item  routerLinkActive="selected">
                <a [routerLink]="['/profile/edit']" class="relative" routerLinkActive="selected" >{{ 'com.usafebox.menu.profile.edit' | translate}}</a>
            </mat-list-item>
            <mat-list-item  routerLinkActive="selected">
                <a [routerLink]="['/profile/change-password']" class="relative" routerLinkActive="selected" >{{ 'com.usafebox.menu.change-password' | translate}}</a>
            </mat-list-item>
        </mat-nav-list>
        <mat-list-item appAccordionLink routerLinkActive="selected" group="support" (click)="scrollToTop()">       
            <a class="" appAccordionToggle [routerLink]="['/support']" >
                <mat-icon>help</mat-icon>
                <span>{{ 'com.usafebox.menu.support' | translate }}</span>
                <span fxFlex></span>
            </a>
        </mat-list-item>
    </mat-list-item>
</mat-nav-list>


<div *ngIf="!minisidebar">
    <div class="side-img-bottom">
        <img src="assets/images/side-bottom.png" alt="side" width="299" > 
    </div>
</div>