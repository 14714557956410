import { Component, OnInit } from '@angular/core';
import { UsersBusinessService } from './services/business/users.business.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


  constructor ( private usersBusinessService : UsersBusinessService ){

  }




  ngOnInit(): void {
       // Get browser language
       let navigatorLanguage : string = navigator.language.split("-")[0].toLowerCase();
       this.usersBusinessService.setDefaultTranslationsLanguage( navigatorLanguage ); // TODO Check language codes
  }




}
