import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Attachment } from '../../models/Attachment';
import { Category } from '../../models/Category';
import { Deposit } from '../../models/Deposit';
import { SafesApiService } from '../api/safes.api.service';
import { SnackbarService } from '../snackbar.service';


@Injectable()
export class SafesBusinessService{


    constructor(  private safesApiService : SafesApiService,
                  private snackbarService : SnackbarService,
                  private router : Router ){

            
    }


}