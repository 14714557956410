import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserProfile } from '../../models/UserProfile';
import { UserProfileFormData } from '../../models/UserProfileFormData';
import { RegisterFormData } from '../../models/RegisterFormData';
import { ChangePasswordFormData } from '../../models/ChangePasswordFormData';
import { Contact } from '../../models/Contact';
import { ContactConfirmFormData } from '../../models/ContactConfirmFormData';
import { map } from 'rxjs/operators';
import { EncryptionService } from '../encryption/encryption.service';
import { ContactInvitationFormData } from '../../models/ContactInvitationFormData';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsbConstants } from '../../constants/UsbConstants';
import { Register } from '../../models/Register';
import { ForgotPasswordQuestion } from '../../models/ForgotPasswordQuestion';
import { ForgotResetFormData } from '../../models/ForgotResetFormData';
import { ForgotPasswordActivation } from '../../models/ForgotPasswordActivation';


@Injectable()
export class UsersApiService {

    private userProfilebaseUrl = environment.gatewayUrl + "/users/api/v1";

    private headers = new HttpHeaders( );


    constructor( public http:HttpClient,
                 private encryptionService:EncryptionService,
                 private spinner: NgxSpinnerService  ) { 

        // When you use this header, DTE is enabled ( DH )
        this.headers = this.headers.set( UsbConstants.HEADER_DTE, UsbConstants.DTE_JSON);
    }


        
/*================================== Register ====================================================================*/


    /**
     * Register a new user
     * DTE is mandatory on server side
     * @param userProfile 
     */
    public register( register : RegisterFormData  ) : Observable<Register>{
        register = this.encryptionService.sanitizeRegister( register );
        return this.http.post<Register>( `${this.userProfilebaseUrl}/public/register`, register, { headers: this.headers } ).pipe(
            map(data => {
                return data;
            })
        );
    }  



    /**
     * Activate user account
     * DTE is mandatory on server side
     * @param userProfile 
     */
    public registerActivate( activationCode : string, recaptchaResponse : string  ) : Observable<Boolean>{
        activationCode =  this.encryptionService.sanitize( activationCode) ;
        recaptchaResponse = this.encryptionService.sanitize(recaptchaResponse);
        return this.http.post<Boolean>( `${this.userProfilebaseUrl}/public/register/activation`, { activationCode  : activationCode,  recaptchaResponse : recaptchaResponse }, { headers: this.headers } ).pipe(
            map(data => {
                return data;
            })
        );
    }  

/*================================== Forgot Password ====================================================================*/


    /**
     * Get a list of forgot password questions
     * DTE is mandatory on server side
     */
    public getForgotPasswordQuestions() : Observable<ForgotPasswordQuestion[]>{
        return this.http.get<ForgotPasswordQuestion[]>(`${this.userProfilebaseUrl}/public/forgot-password/questions`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }  




    /**
     * Start forgot password request : Server send an email to get an activation email link
     * DTE is mandatory on server side
     */
    public forgotPasswordEmailRequest( forgotPasswordRequestForm :  any ) : Observable<Boolean>{
        forgotPasswordRequestForm.loginName =  this.encryptionService.sanitize( forgotPasswordRequestForm.loginName  );
        forgotPasswordRequestForm.email =  this.encryptionService.sanitize( forgotPasswordRequestForm.email  );
        forgotPasswordRequestForm.recaptchaResponse =  this.encryptionService.sanitize( forgotPasswordRequestForm.recaptchaResponse  );
        return this.http.post<Boolean>(`${this.userProfilebaseUrl}/public/forgot-password/request`, forgotPasswordRequestForm , { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }  


    /**
     * Check activation code and get a list of user questions
     * DTE is mandatory on server side
     */
    public forgotPasswordActivation( activationCode :  string, recaptchaResponse : string ) : Observable<ForgotPasswordActivation>{
        activationCode =  this.encryptionService.sanitize( activationCode) ;
        recaptchaResponse = this.encryptionService.sanitize(recaptchaResponse);
        return this.http.post<ForgotPasswordActivation>(`${this.userProfilebaseUrl}/public/forgot-password/activation`, { activationCode : activationCode,  recaptchaResponse : recaptchaResponse  }, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }  



        /**
     * Check activation code and get a list of user questions // TODO Santize form !
     * DTE is mandatory on server side
     */
    public forgotPasswordReset( forgotResetFormData :  ForgotResetFormData ) : Observable<Boolean>{
        return this.http.post<Boolean>(`${this.userProfilebaseUrl}/public/forgot-password/reset`, forgotResetFormData, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }  


/*================================== User profile ====================================================================*/


    /**
     * Get logged user profile and encryption keys
     * DTE is mandatory on server side
     */
    public getMyUserProfile() : Observable<UserProfile>{
        return this.http.get<UserProfile>(`${this.userProfilebaseUrl}/private/user-profile/me`, { headers: this.headers } ).pipe(
            map((userProfile: UserProfile) => {
                return userProfile;
            })
        );
    }  
     

    /**
     * Update logged user profile
     * DTE is mandatory on server side
     * @param userProfile 
     */
    public putMyUserProfile( userProfile : UserProfileFormData ) : Observable<UserProfile>{
        return this.http.put<UserProfile>( `${this.userProfilebaseUrl}/private/user-profile/me`, userProfile, { headers: this.headers }).pipe(
            map((userProfile: UserProfile) => {
                return userProfile;
            })
        );
    } 


    /**
     * Update password
     * DTE is mandatory on server side
     * @param changePasswordFormData 
     */
    public putUserChangeMyPassword( changePasswordFormData : ChangePasswordFormData  ) : Observable<boolean>{
        return this.http.put<boolean>( `${this.userProfilebaseUrl}/private/user-profile/me/change-password`, changePasswordFormData, { headers: this.headers } ).pipe(
            map(data => {
                return data;
            })
        );
    } 


/*================================== Contacts ====================================================================*/


    /**
     * Get logged user contacts and profile encryptions keys
     * DTE is mandatory on server side
     */
    public getMyContacts() : Observable<Contact[]>{
        return this.http.get<Contact[]>(`${this.userProfilebaseUrl}/private/contact/me`, { headers: this.headers }).pipe(
            map((contacts: Contact[]) => {
                return contacts;
            })
        );
    }
    

    /**
     * Create a new contact invitation
     * DTE is mandatory on server side
     * @param contactInvitationFormData 
     */
    public inviteContact( contactInvitationFormData: ContactInvitationFormData) : Observable<Contact>{
        return this.http.post<Contact>(`${this.userProfilebaseUrl}/private/contact/me`, contactInvitationFormData, { headers: this.headers } ).pipe(
            map(data => {
                return data;
            })
        );
    }
    
    
    /**
     * Confirm an inviation from a contact
     * DTE is mandatory on server side
     * @param contactConfirmFormData 
     */
    public confirmContact( contactConfirmFormData: ContactConfirmFormData) : Observable<Boolean>{
        return this.http.post<Boolean>(`${this.userProfilebaseUrl}/private/contact/me/confirm`, contactConfirmFormData, { headers: this.headers } ).pipe(
            map(data => {
                return data;
            })
        );
    }   


    /**
     * Delete a contact
     * DTE is mandatory on server side
     * @param contact 
     */
    public deleteContact( contact : Contact) : Observable<Boolean>{
        return this.http.delete<Boolean>(`${this.userProfilebaseUrl}/private/contact/me/${contact.id}`, { headers: this.headers } ).pipe(
            map(data => {
                return data;
            })
        );
    }  



} 