import { ChangeDetectorRef, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Deposit } from '../../models/Deposit';
import { Safe } from '../../models/Safe';
import { Category } from '../../models/Category';
import { Attachment } from '../../models/Attachment';
import { Space } from '../../models/Space';
import { SpaceUser } from '../../models/SpaceUser';
import { CategoryFormData } from '../../models/CategoryFormData';
import { DepositFormData } from '../../models/DepositFormData';
import { SafeFormData } from '../../models/SafeFormData';
import { SafeTemplate } from '../../models/SafeTemplate';
import { SpaceUserFormData } from '../../models/SpaceUserFormData';
import { CategoryTemplate } from '../../models/CategoryTemplate';
import { EncryptionService } from '../encryption/encryption.service';
import { map, tap, timeout } from 'rxjs/operators';
import { Indicators } from '../../models/Indicators';
import { Dashboard } from '../../models/Dashboard';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { TransfertModalContent } from '../../modals/transfert-modal/transfert-modal.component';
import { UsbConstants } from '../../constants/UsbConstants';
import { UserProfile } from '../../models/UserProfile';
import { SharedService } from '../shared.service';
import {  SignaturePrepareRequest } from '../../models/SignaturePrepareRequest';
import { SignatureIntegrationRequest } from '../../models/SignatureIntegrationRequest';
import { CryptoService } from '../crypto.service';
import { saveAs } from 'file-saver';
import { AttachmentInfos } from '../../models/AttachmentInfos';

@Injectable()
export class SafesApiService {


    private baseUrl = environment.gatewayUrl + "/safes/api/v1";

    private headers = new HttpHeaders( );
    private headersFile = new HttpHeaders( );
    private dte : boolean = false;

    constructor( public http:HttpClient,
                 private encryptionService: EncryptionService,
                 private cryptoService : CryptoService,
                 private spinner: NgxSpinnerService,
                 private sharedService : SharedService,
                 public dialog: MatDialog ) { 


        // DTE is optionnal ( from user profile )              
        this.sharedService.onProfileLoaded.subscribe((userProfile:UserProfile) => {
             this.dte = userProfile.dte;
             if ( userProfile.dte ) {

                  this.headers = new HttpHeaders( );
                  this.headers = this.headers.set( UsbConstants.HEADER_DTE, UsbConstants.DTE_JSON);

                  this.headersFile = new HttpHeaders( );
                  this.headersFile = this.headers.set( UsbConstants.HEADER_DTE, UsbConstants.DTE_MULTIPART);
             }
        });
     
    }


/*=================== Spaces ====================================================================================*/


    /**
     * Get available spaces list
     * 
     * Actually DISABLED on server !
     */
    public getSpaces() : Observable<Space[]>{
        return this.http.get<Space[]>(`${this.baseUrl}/private/space`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * Get a space
     * @param spaceId 
     */
    public getSpace( spaceId : string ) : Observable<Space>{
        return this.http.get<Space>(`${this.baseUrl}/private/space/${spaceId}`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }

/*=================== SpaceUser ====================================================================================*/


    /**
     * 
     */
    public getSpacesUser() : Observable<SpaceUser[]>{
        return this.http.get<SpaceUser[]>(`${this.baseUrl}/private/space-user`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * 
     * @param spaceUserId 
     */
    public getSpaceUser( spaceUserId : string ) : Observable<SpaceUser>{
        return this.http.get<SpaceUser>(`${this.baseUrl}/private/space-user/${spaceUserId}`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * Actually DISABLED on server !
     * @param spaceUser 
     */
    public postSpaceUser( spaceUser : SpaceUserFormData ) : Observable<SpaceUser>{
        return this.http.post<SpaceUser>(`${this.baseUrl}/private/space-user`, spaceUser, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * Actually DISABLED on server !
     * @param spaceUser 
     */
    public putSpaceUser( spaceUser : SpaceUserFormData ) : Observable<SpaceUser>{
        return this.http.put<SpaceUser>(`${this.baseUrl}/private/space-user`, spaceUser, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * Actually DISABLED on server !
     * @param spaceUser 
     */
    public deleteSpaceUser( spaceUser : SpaceUser ) : Observable<SpaceUser>{
        return this.http.delete<SpaceUser>(`${this.baseUrl}/private/space-user/${spaceUser.id}`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }

/*=================== Safes ====================================================================================*/


    /**
     * 
     * @param spaceId 
     */
    public getSafes( spaceId : string ) : Observable<Safe[]>{
        return this.http.get<Safe[]>(`${this.baseUrl}/private/safe/space/${spaceId}`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * 
     * @param safeId 
     */
    public getSafe( safeId : string ) : Observable<Safe>{
        return this.http.get<Safe>(`${this.baseUrl}/private/safe/${safeId}`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }



    /**
     * Actually DISABLED on server !
     * @param safeFormData 
     */
    public postSafe( safeFormData : SafeFormData ) : Observable<Safe>{
        return this.http.post<Safe>(`${this.baseUrl}/private/safe`, safeFormData, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * Actually DISABLED on server !
     * @param safe 
     */
    public putSafe( safe : SafeFormData ) : Observable<Safe>{
        return this.http.put<Safe>(`${this.baseUrl}/private/safe`, safe, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * Actually DISABLED on server !
     * @param safe 
     */
    public deleteSafe( safe : Safe ) : Observable<Safe>{
        return this.http.delete<Safe>(`${this.baseUrl}/private/safe/${safe.id}`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * Actually DISABLED on server !
     * @param spaceId 
     */
    public getSafeTemplates( spaceId : string ) : Observable<SafeTemplate[]>{
        return this.http.get<SafeTemplate[]>(`${this.baseUrl}/private/safe/space/${spaceId}/templates`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }

/*=================== Categories ====================================================================================*/


    /**
     * 
     * @param safeId 
     */
    public getCategories( safeId : string ) : Observable<Category[]>{
        return this.http.get<Category[]>(`${this.baseUrl}/private/category/safe/${safeId}`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * 
     * @param categoryId 
     */
    public getCategory( categoryId : string ) : Observable<Category>{
        return this.http.get<Category>(`${this.baseUrl}/private/category/${categoryId}`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * 
     * @param category 
     */
    public postCategory( category : CategoryFormData ) : Observable<Category>{
        return this.http.post<Category>(`${this.baseUrl}/private/category`, category, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * 
     * @param category 
     */
    public putCategory( category : CategoryFormData ) : Observable<Category>{
        return this.http.put<Category>(`${this.baseUrl}/private/category`, category, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * 
     * @param category 
     */
    public deleteCategory( category : Category ) : Observable<Category>{
        return this.http.delete<Category>(`${this.baseUrl}/private/category/${category.id}`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


    /**
     * 
     * @param safeTemplateId 
     */
    public getCategoryTemplates( safeTemplateId : string ) : Observable<CategoryTemplate[]>{
        return this.http.get<CategoryTemplate[]>(`${this.baseUrl}/private/category/safetemplate/${safeTemplateId}/templates`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }
    

/*=================== Deposits ====================================================================================*/


    /**
     * 
     * @param categoryId 
     */
    public getDeposits( categoryId : string ) : Observable<Deposit[]>{      
        return this.http.get<Deposit[]>(`${this.baseUrl}/private/deposit/category/${categoryId}`, { headers: this.headers }).pipe(
            map((deposits: Deposit[]) => {
                return deposits;
            })
        );
    }

  
    /**
     * 
     * @param depositId 
     */
    public getDeposit( depositId : string ) : Observable<Deposit>{        
        return this.http.get<Deposit>(`${this.baseUrl}/private/deposit/${depositId}`, { headers: this.headers }).pipe(
            map((deposit: Deposit) => {
                return deposit;
            })
        );
    }


    /**
     * 
     * @param deposit 
     */
    public putDeposit( deposit : DepositFormData ) : Observable<Deposit>{
        return this.http.put<Deposit>(`${this.baseUrl}/private/deposit`, deposit, { headers: this.headers }).pipe(
            map((deposit: Deposit) => {
                return deposit;
            })
        );
    } 
    
    
    /**
     * 
     * @param deposit 
     */
    public postDeposit( deposit : DepositFormData  ) : Observable<Deposit>{
        return this.http.post<Deposit>(`${this.baseUrl}/private/deposit`, deposit, { headers: this.headers }).pipe(
            map((deposit: Deposit) => {
                return deposit;
            })
        );
    }  


    /**
     * 
     * @param depositId 
     */
    public deleteDeposit( depositId : string  ) : Observable<Boolean>{
        return this.http.delete<Boolean>(`${this.baseUrl}/private/deposit/${depositId}`, { headers: this.headers } ).pipe(
            map(data => {
                return data;
            })
        );
    } 
    

/*=================== Attachments ====================================================================================*/


    /**
     * Get a list of attachments without content
     * @param deposit 
     */
    public getAttachments( deposit : Deposit ) : Observable<Attachment[]>{
        return this.http.get<Attachment[]>(`${this.baseUrl}/private/attachment/deposit/${deposit.id}`, { headers: this.headers }).pipe(
            map((attachments: Attachment[]) => {
                return attachments;
            })
        );
    }

    
    /**
     * get an attachment with content
     * @param attachmentId 
     * @param openTransfertModal 
     */
    public getAttachment( attachmentId : string , openTransfertModal : boolean ) : Observable<Attachment>{
        var params = new HttpParams();
        if ( openTransfertModal ){
            params = params.append('spinner', 'false');  
            this.openTransfertModal();
       }
        return this.http.get<Attachment>(`${this.baseUrl}/private/attachment/${attachmentId}`, { params : params, headers: this.headers }).pipe(
            map((attachment: Attachment) => {
                attachment.contentFile = this.cryptoService.stringToBytes( atob( attachment.contentFile ));
                this.closeModals();
                return attachment;
            })
        );
    }


    /**
     * Get an attachment infos
     * @param attachmentId 
     */
    public getAttachmentInfos( attachmentId : string ) : Observable<AttachmentInfos>{
        return this.http.get<AttachmentInfos>(`${this.baseUrl}/private/attachment/${attachmentId}/infos`, { headers: this.headers }).pipe(
            map((attachmentInfos: AttachmentInfos) => {
                return attachmentInfos;
            })
        );
    }


    /**
     * Delete an attachment
     * @param attachment 
     */
    public deleteAttachment( attachment : Attachment  ) : Observable<Boolean>{
        return this.http.delete<Boolean>(`${this.baseUrl}/private/attachment/${attachment.id}`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }  


    
    /**
     * Delete an attachment
     * @param attachmentId 
     */
    public deleteAttachmentById( attachmentId : string  ) : Observable<Boolean>{
        return this.http.delete<Boolean>(`${this.baseUrl}/private/attachment/${attachmentId}`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }  



    /**
     * Post an new attachment file
     * 
     * @param file 
     * @param deposit 
     */
    public async postAttachment ( file : File, deposit : Deposit, isBigFile: boolean  ) : Promise<Observable<Attachment>>{

        if ( this.dte ){
            file = await this.encryptionService.encryptFileWithDH(file);
        }

        let formData = new FormData(); 
        formData.append( 'file', file , file.name ); 

        var params = new HttpParams();
        if ( isBigFile ){ // For big files, show modal
             params = params.append('spinner', 'false');  
        }
        return this.http.post<Attachment>( `${this.baseUrl}/private/attachment/deposit/${deposit.id}`, formData, { params : params, headers: this.headersFile }).pipe( timeout(300000), 
            map((attachment: Attachment) => {         
                this.closeModals();
                return attachment;
            })
        );
    }




/*=================== Space file ====================================================================================*/


    /**
     * 
     * @param spaceId 
     * @param spaceFileId 
     */
    public getSpaceFileContent( spaceId: string, spaceFileId : string ) {
        return `${this.baseUrl}/private/space-file/space/${spaceId}/file/${spaceFileId}/content`
    }


/*=================== Indicators ====================================================================================*/


    
    /**
     * Get deposits informations ( counts, .... )
     */
    public getIndicators() : Observable<Indicators>{
        return this.http.get<Indicators>(`${this.baseUrl}/private/indicators`, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }


/*=================== Dashboard ====================================================================================*/


    /**
     * Get dashboard infos ( counts, ... )
     */
    public getDashboard() : Observable<Dashboard>{
        return this.http.get<Dashboard>(`${this.baseUrl}/private/dashboard`, { headers: this.headers }).pipe(
            map((dashboard: Dashboard) => {
                return dashboard;
            })
        );
    }


    /**
     * Get dashboard unread deposits
     */
    public getDashboardUnreads() : Observable<Deposit[]>{
        var params = new HttpParams();
        params = params.append('spinner', 'false');        
        return this.http.get<Deposit[]>(`${this.baseUrl}/private/deposit/unreads`, { params : params, headers: this.headers }).pipe(
            map((deposits: Deposit[]) => {
                return deposits;
            })
        );
    }


    
/*=============== SIGNATURE ==============================================================================================*/

    /**
     * Post signature prepare
     * @param signaturePrepareRequest
     */
    public prepareSignature( attachment : Attachment,  signaturePrepareRequest : SignaturePrepareRequest  ) : Observable<Attachment>{
        return this.http.post<Attachment>(`${this.baseUrl}/private/signature/attachment/${attachment.id}/prepare`, signaturePrepareRequest, { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }



    /**
     * Post signature integration 
     * @param signatureIntegrationRequest
     */
    public integrateSignature( attachment : Attachment, signatureIntegrationRequest : SignatureIntegrationRequest  ) : Observable<Attachment>{
        return this.http.post<Attachment>(`${this.baseUrl}/private/signature/attachment/${attachment.id}/integrate`, signatureIntegrationRequest, { headers: this.headers }).pipe(
            map(attachment => {
                return attachment;
            })
        );
    }
   

    
/*=================== Modals ====================================================================================*/

    
    /**
     * Open transfert wait modal
     */
    private openTransfertModal( ){
        this.dialog.open( TransfertModalContent );
    }


    /**
     * Close all modals
     */
    private closeModals(){
         this.dialog.closeAll();
    }

} 