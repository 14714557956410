import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { EncryptionService } from '../encryption/encryption.service';
import { UsbConstants } from '../../constants/UsbConstants';
import { TOTPQrCode } from '../../models/TOTPQrCode';


@Injectable()
export class SauthApiService {

    private baseUrl =  environment.gatewayUrl + "/sauth/api/v1";

    private headers = new HttpHeaders( );


    constructor( public http:HttpClient,
                 private encryptionService:EncryptionService  ) { 

        // When you use this header, DTE is enabled ( DH )
        this.headers = this.headers.set( UsbConstants.HEADER_DTE, UsbConstants.DTE_JSON);
    }


/*================================== TOTP ====================================================================*/



    public getTOTPActivation() : Observable<Boolean>{
        return this.http.get<Boolean>(`${this.baseUrl}/private/totp/activation`, { headers: this.headers } ).pipe(
            map(data => {
                return data;
            })
        );
    }



    public getTOTPQrCode( activationCode : string ) : Observable<TOTPQrCode>{ 
        return this.http.post<TOTPQrCode>(`${this.baseUrl}/private/totp/qrcode`, { activationCode : this.encryptionService.sanitize(activationCode) } , { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }




    public postTOTPRegister( secret : string ) : Observable<Boolean>{
        return this.http.post<Boolean>(`${this.baseUrl}/private/totp/registration`, { secret : this.encryptionService.sanitize(secret) } , { headers: this.headers }).pipe(
            map(data => {
                return data;
            })
        );
    }



    public postTOTPAuthenticationCode( code : string ) : Observable<Boolean>{
        return this.http.post<Boolean>(`${this.baseUrl}/private/totp/code`, { code : this.encryptionService.sanitize(code) }, { headers: this.headers } ).pipe(
            map(data => {
                return data;
            })
        );
    }



} 