
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  OnInit
} from '@angular/core';
import { AppHeaderComponent } from './header/header.component';
import { AppSidebarComponent } from './sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './breadcrumb/breadcrumb.component';

import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { UsersApiService } from '../../services/api/users.api.service';
import { UsersBusinessService } from '../../services/business/users.business.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaConstants } from '../../constants/MediaConstants';
import { UserProfile } from '../../models/UserProfile';
import { SharedService } from '../../services/shared.service';
import { UserSettings } from '../../models/UserSettings';
import { StorageParams } from '../../constants/StorageParams';

/** @title Responsive sidenav */
@Component({
  selector: 'app-main-layout',
  templateUrl: 'main.component.html',
  styleUrls: []
})
export class MainComponent implements OnDestroy, OnInit {

  public defaultUserSettings = new UserSettings( "ltr", false, false, false, false, false, false );
  public userSettings : UserSettings;
  public userProfile : UserProfile;


  public showHide: boolean;
  public url: string;
  public sidebarOpened;

  public showSearch = false;
  
  public config: PerfectScrollbarConfigInterface = {};

  public mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

    
  constructor( public router: Router,
               private changeDetectorRef: ChangeDetectorRef,
               private media: MediaMatcher,
               private usersApiService : UsersApiService,
               private usersBusinessService : UsersBusinessService,
               private sharedService : SharedService ) {

    this.mobileQuery = media.matchMedia( MediaConstants.MIN_WIDTH );
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener ('change', this._mobileQueryListener);

    this.sharedService.onProfileLoaded.subscribe((data:UserProfile) => {

        this.userProfile = data;

        // Refresh page ?
        if ( data == null ){
          this.usersBusinessService.loadUserProfile();
        }
    });


  }

  
  /**
   * 
   */
  ngOnInit(): void {
    this.loadUserSettings();
  }


  /**
   * Load user settings
   */
  private loadUserSettings(){
      let userSettingsStorage = localStorage.getItem( StorageParams.USER_SETTINGS );
      if ( userSettingsStorage ){
          this.userSettings = JSON.parse( userSettingsStorage );
      }else{
          this.userSettings = this.defaultUserSettings; 
      }
  }

  /**
   * Close nav after touch on mobile devices
   * @param snav 
   */
  public closeSideNav( snav : any ) {
      if ( ! this.mobileQuery.matches ){
          snav.close();
      }
  }





  /**
   * Mobile event listener remove
   */
  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }


  /**
   * Save settings in local storage
   */
  public saveUserSettings(){
    localStorage.setItem( StorageParams.USER_SETTINGS, JSON.stringify(this.userSettings));
  }


}
