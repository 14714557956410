
export class StorageParams {

    static SPACE_ID_PARAM = "30ce1584-5748-42e2-b730-0b5eeb23f598";
    static SPACE_NAME_PARAM = "149ad0e3-20c4-414e-812c-597c8ac631b2";

    static SAFE_ID_PARAM = "dbd8b033-99bb-4bcd-8099-15cbe4b6664c";
    static SAFE_NAME_PARAM = "f2738665-74ba-41f3-a991-6e362f015ee2";

    static CATEGORY_ID_PARAM = "17abe1e8-07aa-439f-8d76-29df57bc585f";
    static CATEGORY_NAME_PARAM = "3a218104-da83-424f-8352-a37ba97eba95";

    static DEPOSIT_ID_PARAM = "91dc82e9-57b3-40ba-80f1-ccfd1bd90040";
    static DEPOSIT_NAME_PARAM = "4624c98d-0c55-4e46-afa5-1b241d118bb4";

    static ATTACHMENT_ID_PARAM = "464d6034-3b39-45a5-a7ee-d808a4ec4ca2";
    static ATTACHMENT_NAME_PARAM = "fbedd34a-ca3f-428d-8540-04b45180a6a4";
    static ATTACHMENT_CONTENT_TYPE = "45448860-efe8-4c0e-adad-720cc61202b2";


    static SELECTED_INDEX = "4eff432c-bf8e-4692-b86e-beabb073f8cf";
    static CONFIG = "97d9af4a-bc75-45ce-8ba1-a479d8dd32f4";
    static USER_PROFILE = "ffef07de-81ea-4fcb-ac12-51a69b2aef43";
    static VIEW_TABLE_MODE = "beda526e-4be4-4696-92d7-4dc2b5fd94f7";


    static DH_SECRET_KEY = "1d4f9ed5-22ea-40b4-b393-5ed3c8a43555";
    static CSRF = "57318b30-0c5e-4b78-99b8-d7201ade88ed";
    
    static USER_SETTINGS = "5d17d32b-7245-4d96-a045-6b49cda08c25";

}
