import {
  Component,
  OnInit,
  AfterViewInit
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UsbConstants } from '../../../constants/UsbConstants';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: []
})
export class AppBreadcrumbComponent implements OnInit, AfterViewInit {

  public pageInfo;


  constructor( private router: Router,
               private activatedRoute: ActivatedRoute,
               private translate:  TranslateService,
               private titleService: Title) {

    this.router.events
       .pipe(filter(event => event instanceof NavigationEnd))
       .pipe(map(() => this.activatedRoute))
       .pipe(
         map(route => {
           while (route.firstChild) {
             route = route.firstChild;
           }
           return route;
         })
       )
       .pipe(filter(route => route.outlet === 'primary'))
       .pipe(mergeMap(route => route.data))
       .subscribe(event => {

        console.log("AppBreadcrumbComponent event  : " , event )
        
        this.pageInfo = event;
        let titleKey = event['title'];

        if ( titleKey ){
            this.getTitleContent(titleKey).subscribe( title => {
              this.titleService.setTitle(title); 
              this.pageInfo.title = title;  
            });
        }


    });
  }

  /**s
   * 
   * @param title 
   */
  private getTitleContent ( title  : string ) : Observable<string> {
      if ( title.includes( UsbConstants.LABELS_PREFIX ) ){
        return this.translate.get( title );
      }else if ( title.includes( UsbConstants.STORAGE_PREFIX )){
        return of(sessionStorage.getItem( title.replace( UsbConstants.STORAGE_PREFIX, ""))) ; 
      }else{
        return of(title);
      }  
  }



    /**
   * stop event Propagation to prevent some bugs
   * @param event 
   */
  public stopPropagation(event: any){
    event.stopPropagation();
    event.preventDefault();
  }


  ngAfterViewInit(): void {


    
  }


  
  ngOnInit() {


  }

}
