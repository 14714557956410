import { Injectable } from '@angular/core';
import * as forge from 'node-forge'
import { UsbConstants } from '../constants/UsbConstants';
import { StorageParams } from '../constants/StorageParams';
import { UserProfile } from '../models/UserProfile';



/**
 * @doc forge lib : https://www.npmjs.com/package/node-forge
 */
@Injectable()
export class CryptoService{


    private PUBLIC_KEY_START_DELIM = this.hextoa("2d2d2d2d2d424547494e205055424c4943204b45592d2d2d2d2d");
    private PUBLIC_KEY_END_DELIM = this.hextoa("2d2d2d2d2d454e44205055424c4943204b45592d2d2d2d2d");

    private PRIVATE_KEY_START_DELIM = this.hextoa("2d2d2d2d2d424547494e205253412050524956415445204b45592d2d2d2d2d");
    private PRIVATE_KEY_END_DELIM = this.hextoa("2d2d2d2d2d454e44205253412050524956415445204b45592d2d2d2d2d");   

    private IV_LENGTH_GCM = 12;
    private TAG_LENGTH_GCM = 16;
    private PBKDF2_ITERATIONS = 1024;
    private PBKDF2_KEY_LENGTH_BYTES = 32;
    private AES_GCM = "AES-GCM";



    private secureStorageDerivedKey : any;


    constructor( ){
        this.secureStorageDerivedKey = this.deriveKeyFromPasswordAndSalt( "0d88e0f5-a583-40db-9dc3-b4c51dc74f4b", "20c085f5-6b39-4bc4-a7cc-6ae03f08efc3" );         
    }




        /**
     * 
     * @param data 
     * @param secretKey 
     */
    public decryptWithSecretkey  ( encryptedDataBase64 : any, secretKey  : any   ){
        let encryptedDataBytes = atob( encryptedDataBase64 );
        return this.decryptAes( encryptedDataBytes, secretKey);
    }


    /**
     * 
     * @param encryptedDataBase64 
     * @param secretKey 
     */
    public decryptBytesWithSecretKey ( encryptedDataBase64 : any, secretKey  : any  ) {
        let encryptedDataBytes = atob( encryptedDataBase64 );
        let decrypted = this.decryptAes( encryptedDataBytes, secretKey);
        return this.stringToBytes( decrypted.bytes() );
    }



     /**
     * 
     * @param data 
     */
    public encrypAesBase64 ( data : any, secretKey : any ){
        let encryptedDataBytes = this.encryptAes( data , secretKey);
        return forge.util.encode64(encryptedDataBytes);
    }



    /**
     * 
     * @param encryptedDataBase64 
     */
    public decryptAesBase64( encryptedDataBase64 : any, secretKey ) {
        let encryptedDataBytes = atob( encryptedDataBase64 );
        let decrypted = this.decryptAes( encryptedDataBytes, secretKey);
        return decrypted;
    }



    /**
     * 
     * @param data 
     */
    public encryptWithDH ( data : any ){
        let secretKeyBase64  = this.getSecureStorageItem( StorageParams.DH_SECRET_KEY );
        let encryptedDataBytes = this.encryptAes( data , atob (secretKeyBase64));
        return forge.util.encode64(encryptedDataBytes);
    }


        /**
     * 
     * @param data 
     */
    public decryptWithDH ( encryptedDataBase64 : any ){
        let secretKeyBase64  = this.getSecureStorageItem( StorageParams.DH_SECRET_KEY );
        let encryptedDataBytes = atob( encryptedDataBase64 );
        let decrypted = this.decryptAes( encryptedDataBytes, atob ( secretKeyBase64 ));
        return decrypted;
    }


            /**
     * 
     * @param data 
     */
    public decryptBytesWithDH ( encryptedDataBytes :  any ){
        let secretKeyBase64  = this.getSecureStorageItem( StorageParams.DH_SECRET_KEY );
        let decrypted = this.decryptAes( encryptedDataBytes, atob ( secretKeyBase64 ));
        return this.stringToBytes( decrypted.bytes() );
        //return decrypted.bytes();
    }



        /**
     * 
     * @param data 
     */
    public encryptWitPublicKey ( data : any , publicKeyBase64 : string){
        let publicKeyPem = this.PUBLIC_KEY_START_DELIM + publicKeyBase64 + this.PUBLIC_KEY_END_DELIM;    
        let publicKeyInstance = forge.pki.publicKeyFromPem( publicKeyPem);
        let encryptedDataBytes = this.encryptRsa( data, publicKeyInstance );
        return forge.util.encode64(encryptedDataBytes);
    }





    /**
     * Convert string to bytes array
     * @param str 
     */
    public stringToBytes(str : string ) : any {
        var bytes = new Uint8Array(str.length);
        for (var i = 0; i < str.length; i++) {
            bytes[i] = str.charCodeAt(i);
        }
        return bytes;
    }






    /**
     * @param password 
     * @param salt 
     */
    public deriveKeyFromPasswordAndSalt( password, salt )  {  
        return forge.pkcs5.pbkdf2( password, salt, this.PBKDF2_ITERATIONS, this.PBKDF2_KEY_LENGTH_BYTES);
    }




    /**
     * Encrypt AES 256 GCM
     * @param data 
     * @param secretKey 
     */
    public encryptAes (data, secretKey ) {

        //console.log ( "encryptAes secretKey "  + forge.util.encode64 ( secretKey ));

        // Determine the data type
        let encoding = "utf8";
        if (typeof(data) != 'string') {
            encoding = "raw";
        }

        let iv = forge.random.getBytesSync(this.IV_LENGTH_GCM);
        let cipher = forge.cipher.createCipher( this.AES_GCM, secretKey);
        cipher.start({ iv: iv });
        cipher.update(forge.util.createBuffer(data, encoding));  // Warning
        cipher.finish();
        let encryptedData = cipher.output;
        let tag = cipher.mode.tag;
        //Concatenation of encryptedData + tag + iv
        return encryptedData.data.concat ( tag.data ).concat (iv);
    }




    
    /**
     * Decrypt AES-256 GCM
     * @param encrypted 
     * @param secretKey 
     */
    public decryptAes (encrypted, secretKey) {


        //console.log ( "decryptAes secretKey "  + secretKey );

        //console.log ( "decryptAes secretKey base64 "  + forge.util.encode64 (  secretKey ));

        // Extract data, tag and iv from the encrypted byte array
        let data = encrypted.slice (0, encrypted.length - ( this.IV_LENGTH_GCM + this.TAG_LENGTH_GCM ));
        let tag = encrypted.slice (encrypted.length - ( this.IV_LENGTH_GCM + this.TAG_LENGTH_GCM ),encrypted.length - ( this.IV_LENGTH_GCM) );
        let iv = encrypted.slice (encrypted.length - ( this.IV_LENGTH_GCM  ), encrypted.length  );
        
        let decipher = forge.cipher.createDecipher( this.AES_GCM, secretKey);
        decipher.start({ iv: iv, tag:tag });
        decipher.update(forge.util.createBuffer(data));
        decipher.finish();
        let output = decipher.output;

        //console.log("encryptAes output ", output);
        return output;
    }



    /**
     *  Generate secret key AES-256
     */
    public generateSecretKey () {
		var key256 = forge.md.sha256.create();
        var secretRandom = forge.random.getBytesSync(32);
        key256.update(secretRandom.toString());
		return forge.util.hexToBytes(key256.digest().toHex());
    }



    /**
     *  Generate secret key AES-256
     */
    public generateSecretKeyBase64 () : string {
        let secretKeyBytes = this.generateSecretKey();
        return forge.util.encode64(secretKeyBytes); 
    }



    /**
     *  Bytes to hex
     */
    public bytesTohex ( bytes : any ) : any {
        return forge.util.bytesToHex(bytes); 
    }



    /**
     *  Bytes to hex
     */
    public hexToBytes ( hex : any ) : any {
        return forge.util.hexToBytes(hex); 
    }


        /**
     * 
     * @param data 
     * @param secretKey 
     */
    public encryptWithSecretkey  ( data : any, secretKey  : any   ){
        let encryptedDataBytes = this.encryptAes( data , secretKey );
        return forge.util.encode64(encryptedDataBytes);
    }



    /**
     * 
     * @param hex 
     */
    private hextoa( hex ){
        var hx = hex.toString();
        var str = '';
        for (var i = 0; i < hx.length; i += 2)
            str += String.fromCharCode(parseInt(hx.substr(i, 2), 16));
        return str;
    }




    /**
     * Encrypt data with a public key using RSAES-OAEP/SHA-256
     * @param publickeyBase64 
     * @param data 
     */
    encryptRsa( data, publicKey ){
        var encrypted = publicKey.encrypt(data, 'RSA-OAEP', {
            md: forge.md.sha256.create()
        });
        return encrypted
    }



    /**
     * Decrypt data with a private key using RSAES-OAEP/SHA-256
     * @param publickeyBase64 
     * @param data 
     */
    decryptRsa( encrypted, privateKey ){
        var encrypted = privateKey.decrypt(encrypted, 'RSA-OAEP', {
            md: forge.md.sha256.create()
        });
        return encrypted
    }



    /**
     * 
     * @param itemKey 
     * @param itemValue 
     */
    public setSecureStorageItem( itemKey : string, itemValue: string ){

       sessionStorage.setItem( itemKey, forge.util.bytesToHex(this.encryptAes( itemValue, this.secureStorageDerivedKey)));

       //sessionStorage.setItem(itemKey, itemValue);
    }



    /**
     * 
     * @param itemKey 
     */
    public getSecureStorageItem( itemKey : string ) : string {

        return this.decryptAes( forge.util.hexToBytes( sessionStorage.getItem( itemKey )),  this.secureStorageDerivedKey );


        //return sessionStorage.getItem( itemKey )
    }


}